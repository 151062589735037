import React, { useState } from "react";
import styles from "./ModuleAccordion.module.scss";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

function ModuleAccordian({ testPageData }) {
    const testData = testPageData && testPageData?.content?.content[6].data.target.accordianItems;

    const [openIndex, setOpenIndex] = useState(null);
    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className={styles.accordian}>
            <h2 className={styles.heading}>Frequently Asked Questions</h2>
            <div>
                <hr className={styles.horozontalLine} />
                {testData && testData.length>0 && testData.map((ele, index) => {
                    return (
                        <div key={index}>
                            <div className={styles.wrapcontainer} onClick={() => handleToggle(index)}>
                                <h5 className={styles.headertext}>{ele.headerText}</h5>
                                <button className={styles.arrowbutton}>
                                    {openIndex === index ? <SlArrowUp /> : <SlArrowDown />}
                                </button>
                            </div>

                            {openIndex === index && (
                                <p className={styles.summarytext}>{ele.summaryText}</p>
                            )}
                            <hr />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ModuleAccordian;
